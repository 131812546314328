define("momentumhub/tests/integration/components/admin-dashboard-test", ["qunit", "ember-qunit", "@ember/test-helpers"], function (_qunit, _emberQunit, _testHelpers) {
  "use strict";

  (0, _qunit.module)("Integration | Component | admin-dashboard", function (hooks) {
    (0, _emberQunit.setupRenderingTest)(hooks);
    (0, _qunit.test)("it renders", async function (assert) {
      // Set any properties with this.set('myProperty', 'value');
      // Handle any actions with this.set('myAction', function(val) { ... });

      // await render(hbs`<AdminDashboard />`);
      // await render(hbs`{{admin-dashboard}}`);
      // assert.equal(this.element.textContent.trim(), "");
      assert.ok(true);

      // Template block usage:
      // await render(hbs`
      //   <AdminDashboard>
      //     template block text
      //   </AdminDashboard>
      // `);
      // assert.equal(this.element.textContent.trim(), "template block text");
    });
  });
});