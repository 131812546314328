define("momentumhub/tests/unit/routes/onboarding-new/redirect-test", ["qunit", "ember-qunit"], function (_qunit, _emberQunit) {
  "use strict";

  (0, _qunit.module)('Unit | Route | onboarding-new/redirect', function (hooks) {
    (0, _emberQunit.setupTest)(hooks);
    (0, _qunit.test)('it exists', function (assert) {
      let route = this.owner.lookup('route:onboarding-new/redirect');
      assert.ok(route);
    });
  });
});