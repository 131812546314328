define("momentumhub/tests/helpers/mock-event", ["exports", "ember-drag-drop/test-support/helpers/mock-event"], function (_exports, _mockEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {};
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mockEvent.default;
    }
  });
  Object.keys(_mockEvent).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _mockEvent[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _mockEvent[key];
      }
    });
  });
});