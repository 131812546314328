define("momentumhub/tests/integration/helpers/getvimeolink-test", ["qunit", "ember-qunit", "@ember/test-helpers"], function (_qunit, _emberQunit, _testHelpers) {
  "use strict";

  (0, _qunit.module)("Integration | Helper | getvimeolink", function (hooks) {
    (0, _emberQunit.setupRenderingTest)(hooks);

    // TODO: Replace this with your real tests.
    (0, _qunit.test)("it renders", async function (assert) {
      //   this.set("inputValue", "1234");

      //   await render(hbs`{{getvimeolink inputValue}}`);

      //   // assert.equal(this.element.textContent.trim(), '1234');

      assert.ok(true);
    });
  });
});