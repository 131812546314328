define("momentumhub/tests/helpers/data-transfer", ["exports", "ember-drag-drop/test-support/helpers/data-transfer"], function (_exports, _dataTransfer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dataTransfer.default;
    }
  });
});