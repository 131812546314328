define("momentumhub/tests/integration/components/blockly-component-test", ["qunit", "ember-qunit", "@ember/test-helpers"], function (_qunit, _emberQunit, _testHelpers) {
  "use strict";

  (0, _qunit.module)("Integration | Component | blockly-component", function (hooks) {
    (0, _emberQunit.setupRenderingTest)(hooks);
    (0, _qunit.test)("it renders", async function (assert) {
      //   // Set any properties with this.set('myProperty', 'value');
      //   // Handle any actions with this.set('myAction', function(val) { ... });

      //   await render(hbs`<BlocklyComponent />`);

      //   // assert.equal(this.element.textContent.trim(), "");

      //   // Template block usage:

      //   assert.equal(this.element.textContent.trim(), "template block text");

      assert.ok(true);
    });
  });
});