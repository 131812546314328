define("momentumhub/tests/helpers/drag-drop", ["exports", "ember-drag-drop/test-support/helpers/drag-drop"], function (_exports, _dragDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {};
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dragDrop.default;
    }
  });
  Object.keys(_dragDrop).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _dragDrop[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _dragDrop[key];
      }
    });
  });
});